<template>
  <div id="AccountData">
    <Navbar :fixed="true" :title="$t('user.fundingRecords')" />
    <div class="content">
      <List url="/users/account/lists" :initParams="{ type: 'money' }">
        <template #item="{ item }">
          <div class="tabs-item">
            <div class="list-item">
              <div class="list_img">
                <img class="img" src="@/assets/new/user/tag.svg" alt="">
              </div>
              <div class="list-item-left">
                <p class="list-item-title">{{ item.title }}</p>
                <span class="list-item-indate">{{
                  dateEvent(item.indate)
                }}</span>
              </div>
              <div class="list-item-right">
                <span v-if="item.setup <= 0" class="list-item-money-green">{{
                  item.setup
                }}</span>
                <span v-else class="list-item-money">+{{ item.setup }}</span>
                <span class="list-item-after">{{ $t("newAdd.Balance") }}：{{ item.after }}</span>
              </div>
            </div>
          </div>
        </template>
      </List>
    </div>
  </div>
</template>

<script>
import { countTime, getMyTime, deciTurnMoney, formatDate } from "@/utils/tools";
import { Toast } from "vant";
import { REQUEST_API } from "@/http/api";
import { GET_MONEY_LIST } from "@/api";
import { getTimeStamp } from "@/utils/tools";
import Navbar from "@/components/JNav";
import List from "@/components/List"

export default {
  name: "AccountData",
  components: {
    List,
    Navbar,
  },
  data() {
    return {
      show: false, // 控制日期按钮弹出框的
      // 日期选择的值
      TimeOne: "",
      TimeTwo: "",
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2030, 12, 31),
      currentDate: new Date(),
      type: "", // 控制当前时间点击状态为哪一个
      active: 0, // 标签选中的值
      tabList: [
        {
          // title: this.$t('My.PersonalInformation.AccountData.Semua'),
          title: this.$t("newAdd.all"),
        },
        {
          // title: this.$t('My.PersonalInformation.AccountData.Penarikan'),
          title: this.$t("newAdd.Income"),
        },
        {
          // title: this.$t('My.PersonalInformation.AccountData.IsiUlang'),
          title: this.$t("newAdd.Expenditure"),
        },
      ],
      //显示数据
      TabelData: [
        {
          money: "100.000",
          Num: "SY201105021116039",
          Status: "Pending review",
        },
      ], // 列表渲染的数据
      // 分页的数据
      initParams: {
        type: 'money'
      },
      list: [],
      userInfo: [],
      pageNo: 1,
      lists: [],
    };
  },
  computed: {
    newList() {
      /**
       * <= 1待审核
       * 2成功
       */
      let list = this.lists.map((_) => ({
        ..._,
        stateTxt:
          _.status <= 1 ? this.$t("newAdd.toAudit") : this.$t("newAdd.succeed"),
        amount: deciTurnMoney(_.amount),
      }));
      return list;
    },
  },
  created() {
    this.init();
    // this.recordAll()
    // this.getList();
  },
  methods: {
    dateEvent(indate) {
      return formatDate("Y-m-d H:i:s", indate);
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    handleSeek() {
      if (this.TimeOne && this.TimeTwo) {
        if (getTimeStamp(this.TimeOne) > getTimeStamp(this.TimeTwo)) {
          Toast(this.$t("Hint.failed"));
        } else {
          this.Paging.CurrentPage = 1;
          this.list = [];
          if (!this.active) {
            this.recordAll();
          } else if (this.active === 1) {
            this.recordWithdrawalList();
          } else {
            this.recordRechargeList();
          }
        }
      } else {
        Toast("No Time");
      }
      // if (!getTimeStamp(this.TimeOne) || !getTimeStamp(this.TimeOne)) {
      //   return false
      // }
    },
    //  日期输入框的点击事件
    DateEvent(num) {
      this.show = true;
      if (num === 1) {
        this.type = 1;
      } else {
        this.type = 2;
      }
    },
    //  日期选择的确定事件
    ConfirmEvent(value) {
      let myDate = `${value.getFullYear()}-${value.getMonth() + 1
        }-${value.getDate()}`;
      // let Date = this.module.timestampToTime(this.currentDate.getTime(value))
      if (this.type == 1) {
        this.TimeOne = myDate;
      } else {
        this.TimeTwo = myDate;
      }
      this.show = false;
    },
    //  日期选择的取消事件
    CancelEvent() {
      this.show = false;
    },
    // 分页的点击事件
    PageEvent() {
      this.list = [];
      // if (!this.active) {
      //   this.recordAll()
      // } else if (this.active === 1) {
      //   this.recordWithdrawalList()
      // } else {
      //   this.recordRechargeList()
      // }
      // console.log(this.Paging.CurrentPage)
      this.getList();
    },
    handleTabs(name, title) {
      this.list = [];
      this.Paging.CurrentPage = 1;
      // if (!name) {
      //   this.recordAll()
      // } else if (name === 1) {
      //   this.recordWithdrawalList()
      // } else {
      //   this.recordRechargeList()
      // }
      this.getList();
    },
    getList() {
      let form = {
        type: "money",
        setup: this.active,
        page: this.Paging.CurrentPage,
        subtype: "0",
      };
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      GET_MONEY_LIST(form)
        .then((r) => {
          Toast.clear();
          if (r.data.ret === 1) {
            Toast.clear();
            let dataLists = r.data.data.lists;
            if (r.data.data.currentPage > 1) {
              for (let i = 0; i < dataLists.length; i++) {
                this.lists.push(dataLists[i]);
              }
            } else {
              this.lists = dataLists;
            }
          } else {
            Toast.clear();
            this.$toast(r.data.msg);
          }
        })
        .catch((e) => {
          Toast.clear();
          console.error(e);
        });
    },

    /** API **/
    recordAll() {
      let form = {
        page: this.Paging.CurrentPage,
        pageNum: 10,
        uid: this.userInfo[1].data.player.uid,
        cmd: "201019",
      };
      if (this.TimeOne && this.TimeTwo) {
        form.start_time = getTimeStamp(this.TimeOne);
        form.end_time = getTimeStamp(this.TimeTwo);
      } else {
        delete form.start_time;
        delete form.end_time;
      }
      REQUEST_API(form)
        .then((r) => {
          console.log("全部记录", r.data.notifyList[0].data.data);
          if (!r.data.sc) {
            if (r.data.notifyList[0].data.count) {
              // this.list.push(...r.data.notifyList[0].data.data)
              // this.list = r.data.notifyList[0].data.data
              this.finished =
                r.data.notifyList[0].data.page ===
                r.data.notifyList[0].data.pageSize;
              this.pageNo++;
              if (this.finished) {
                this.loading = false;
              }
              this.Paging.Num = Math.ceil(
                r.data.notifyList[0].data.count /
                r.data.notifyList[0].data.PageSize
              );
              this.Paging.Total = r.data.notifyList[0].data.count;
            } else {
              this.Paging.Num = 0;
              this.Paging.Total = 0;
              this.list = [];
              this.loading = false;
            }
          } else {
            this.Paging.Num = 0;
            this.Paging.Total = 0;
            console.log(this.list);
            Toast(this.$t("Hint.failed"));
          }
          this.list = r.data.notifyList[0].data.data;
        })
        .catch((e) => {
          console.log(e);
        });
    }, // 总和
    recordWithdrawalList() {
      let form = {
        page: this.Paging.CurrentPage,
        pageNum: 10,
        uid: this.userInfo[1].data.player.uid,
        cmd: "201016",
      };
      if (this.TimeOne && this.TimeTwo) {
        form.start_time = getTimeStamp(this.TimeOne);
        form.end_time = getTimeStamp(this.TimeTwo);
      } else {
        delete form.start_time;
        delete form.end_time;
      }
      REQUEST_API(form)
        .then((r) => {
          console.log("提现记录", r.data.notifyList[0].data.data);
          if (!r.data.sc) {
            if (r.data.notifyList[0].data.count) {
              this.list.push(...r.data.notifyList[0].data.data);
              this.finished =
                r.data.notifyList[0].data.page ===
                r.data.notifyList[0].data.pageSize;
              this.pageNo++;
              if (this.finished) {
                this.loading = false;
              }
              this.Paging.Num = Math.ceil(
                r.data.notifyList[0].data.count /
                r.data.notifyList[0].data.PageSize
              );
              this.Paging.Total = r.data.notifyList[0].data.count;
            } else {
              this.Paging.Num = 0;
              this.Paging.Total = 0;
              this.list = [];
              this.loading = false;
            }
          } else {
            this.Paging.Num = 0;
            this.Paging.Total = 0;
            this.list = [];
            this.loading = false;
            Toast(this.$t("Hint.failed"));
          }
          this.list = r.data.notifyList[0].data.data;
        })
        .catch((e) => {
          console.log(e);
        });
    }, // 提现记录
    recordRechargeList() {
      let form = {
        page: this.Paging.CurrentPage,
        pageNum: 10,
        uid: this.userInfo[1].data.player.uid,
        cmd: "201018",
      };
      if (this.TimeOne && this.TimeTwo) {
        form.start_time = getTimeStamp(this.TimeOne);
        form.end_time = getTimeStamp(this.TimeTwo);
      } else {
        delete form.start_time;
        delete form.end_time;
      }
      REQUEST_API(form)
        .then((r) => {
          console.log("充值记录", r.data.notifyList[0].data.data);
          if (!r.data.sc) {
            if (r.data.notifyList[0].data.count) {
              this.list.push(...r.data.notifyList[0].data.data);
              this.finished =
                r.data.notifyList[0].data.page ===
                r.data.notifyList[0].data.pageSize;
              this.pageNo++;
              if (this.finished) {
                this.loading = false;
              }
              this.Paging.Num = Math.ceil(
                r.data.notifyList[0].data.count /
                r.data.notifyList[0].data.PageSize
              );
              this.Paging.Total = r.data.notifyList[0].data.count;
            } else {
              this.Paging.Num = 0;
              this.Paging.Total = 0;
              this.list = [];
              this.loading = false;
            }
          } else {
            this.Paging.Num = 0;
            this.Paging.Total = 0;
            this.list = [];
            this.loading = false;
            Toast(this.$t("Hint.failed"));
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }, // 充值记录
    /** 其他 **/
    init() {
      this.userInfo = this.$cookie.get("userInfo");
    },
    isPositiveNumber(num) {
      if (num) {
      }
      if (typeof num === "number") {
        if (num) {
          return true;
        } else {
          return false;
        }
      } else {
      }
    },
  },
};
</script>

<style lang="less" scoped>
#AccountData {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  @Color: var(--theme);
  @comRadius: 10px;
  @comBG: linear-gradient(#093958, #022c47);
  @wrapPadding: calc(12rem / 16);

  .content {
    padding: 0 calc(15rem / 16);

    .tabs-item {
      margin-top: calc(15rem / 16);
      padding: calc(10rem / 16);
      border-radius: calc(4rem / 16);
      background: var(--light);

      .list-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;

        .list_img {
          .img {
            width: calc(30rem / 16);
            height: calc(22rem / 16);
            display: block;
          }
        }

        &-left {
          flex: 1;
          margin-left: calc(15rem / 16);

          .list-item-title {
            flex: 1;
            font-size: calc(18rem / 16);
            color: var(--textColor);
          }

          .list-item-indate {
            margin-top: calc(8rem / 16);
            font-size: calc(12rem / 16);
            color: var(--gray1);
          }
        }

        &-right {
          display: flex;
          flex-direction: column;

          .list-item-money,
          .list-item-money-green {
            padding-left: calc(6rem / 16);
            font-size: calc(17rem / 16);
            color: var(--red);
          }

          .list-item-money-green {
            color: #1bb019;
          }

          .list-item-after {
            margin-top: calc(3rem / 16);
            font-size: calc(12rem / 16);
            padding-left: calc(6rem / 16);
            color: var(--textColor);
          }
        }
      }
    }
  }
}
</style>